.article-detail-container {
    width: 1000px;
    margin: 58px auto 32px auto; /* 居中 */
    padding: 32px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff; 
    min-height: 200px; /* 可选：设置最小高度，确保阴影可见 */
}

.title {
    font-size: 24px;
    color: #3D3D3D;
    line-height: 32px;
    align-items: center;
    margin-bottom: 32px;
    word-break: break-word;
    text-align: center;
}

.time {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);    
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.content {
    font-size: 16px;
    font-weight: 350;
    line-height: 24px;
    letter-spacing: 0px;

}