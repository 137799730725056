/* 整体容器样式 */
.tab-container-app {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5B8C00; 
    height: 48px; 
  }
  
  /* 标签容器样式 */
  .tabs-app {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px; 
  }
  
  /* 单个标签样式 */
  .tab-app {
    color: white; /* 文字颜色 */
    font-size: 16px; 
    cursor: pointer;
    padding: 0 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    width: 125px;
    box-sizing: border-box; 
    text-align: center; 
  }
  
  .tab-app:not(.active-app):hover {
    background-color: #4A7A00; 
    width: 125px;
    height: 48px;
    box-sizing: border-box;
  }
  
  /* 激活的标签样式 */
  .active-app  {
    background-color: #7CB305;
    color: white;
    margin-top: -12px; 
    height: 60px;
    line-height: 60px;
    width: 125px;
    z-index: 1; 
    position: static;
    text-align: center; 
  }
  .tab-app:focus {
    outline: none; /* 去除默认的点击时的边框 */
}
.below {
  /* box-sizing: border-box; */
  width: 125px;
  height: 40px;
  /* background-color: #7CB305; */
  color: #ffffff;
  /* padding: 18px; */
  text-align: center;
  /* margin-top: 58px; */
  /* align-items: left; */
  z-index: 1;
}