.top{
    position: static;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 380px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    z-index: 0;
    display: flex;	
}

.top-search{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 68px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.85);	
    backdrop-filter: blur(30px);
    z-index: 1;
}

.bg-center{
    position: absolute;
    right: calc((100% - 1000px)/2);
    top:  138px;
    width: 275px;
    height: 155px;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 20px;
    /* background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px); */
    z-index: 3;
}
.sologan{
    position: static;
    width: 300px;
    height: 102px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding: 0px;
    z-index: 0;
}
.sologan-text1{
    position: static;
    left: 0px;
    margin-left: 20px;
    top: 0px;
    width: 285px;
    height: 60px;
    opacity: 1;
    font-family: 思源黑体;
    font-size: 35px;
    font-weight: 500;
    line-height: 76px;
    letter-spacing: 0px;
    color: #FFFFFF;
    z-index: 0;
}
.sologan-text2{
    position: static;
    left: 0px;
    top: 66px;
    width: 285px;
    margin-left: 20px;
    height: 60px;
    opacity: 1;
    font-family: 思源黑体;
    font-size: 35px;
    font-weight: 500;
    line-height: 76px;
    text-align: right;
    letter-spacing: 0px;
    color: #FFFFFF;
    z-index: 1;
}
.ljgd{
    position: static;
    margin-left: 200px;
    margin-top: 10px;
    width: 100px;
    height: 40px;
    border-radius: 8px;
    opacity: 1;
    z-index: 0;
}
.custom-search-input:focus {  
    border-color: #1DA57A !important; 
  }  
.breadcrumb{
    position: relative;
    margin-top: 20px;
    margin-bottom: -50px;
    margin-left: 35%;

}