
/* ------------------- article-group style ------------------- */
.article-group-container-first-right {
  margin-top: 0px;
  box-shadow: 
  0 -1px 2px rgba(0, 0, 0, 0.05), /* 小的上方阴影 */
  0 2px 4px rgba(0, 0, 0, 0.1);   /* 大的下方阴影 */

}
.article-group-container {
  margin-top: 20px;
  box-shadow: 
  0 -1px 2px rgba(0, 0, 0, 0.05), /* 小的上方阴影 */
  0 2px 4px rgba(0, 0, 0, 0.1);   /* 大的下方阴影 */
}

/* ------------------- tab style ------------------- */
/* 整体容器样式 */
.tab-container-article-group {
    /* background-color: #FAFAFA;  */
    height: 46px; 
    width: 100%; 
    /* margin-top: 20px; 这里为啥不起作用？TODO*/
  }
  
  /* 标签容器样式 */
  .tabs-article-group {
    display: flex;
    background-color: #FAFAFA; 
  }
  
  /* 单个标签样式 */
  .tab-article-group {
    color: black; 
    height: 46px;
    width: 90px;
    font-size: 14px;
    line-height: 46px; 
    text-align: center; 
    cursor: pointer;
    /* transition: all 0.2s; */
    transition: border-top 0.3s ease;
    box-sizing: border-box; /* border 会让文字下降 2px，所以要进行这个设置？ */
    line-height: 46px; 

  }
  
  .tab-article-group:hover {
    background-color: #f5f5f5; /* 悬停状态的背景色 */
  }
  
  /* 激活的标签样式 */
  .active-article-group {
    color: #5B8C00;
    /* border-top: 2px solid #5B8C00; */
    position: relative;
  }

  /* 因为直接用 border-top 会导致下移动，所以采用伪元素来实现 */
    .active-article-group::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px; /* 边框的高度 */
      background-color: #5B8C00; /* 边框的颜色 */
      z-index: 1; /* 确保伪元素在文本之上 */
  }

  /* ------------------- simple article list style ------------------- */
/* 列表容器样式 */
.article-simple-list {
  height: 188px; 
  padding: 16px 8px; 
  font-size: 14px; 
  box-sizing: border-box;
  background-color: white;
}

.article-simple-list ul {
  padding: 0; /* 移除默认的内边距 */
  margin: 0; /* 移除默认的外边距 */
  list-style: none; /* 移除列表项的默认样式 */
  background-color: white;
  /* color: rgba(0, 0, 0, 0.65); */
}

/* 列表项样式 */
.article-simple-list li {
  list-style: none;
  cursor: pointer;
  width: 100%;
  line-height: 22px;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin: 0;
  padding: 0;
  background-color: white;
}

.article-simple-list li + li {
  margin-top: 12px; /* 设置每个列表项之间的间距 */
}

.article-simple-list li:hover {
  background-color: #f5f5f5; /* 悬停状态的背景色 */
}