.container {
  display        : flex;
  justify-content: space-between;
  align-items    : flex-start;
  width          : 1000px;
  /* 跟 tab 一样高度 */
  margin         : 30px auto;
  /* 上下边距32px, */
  /* background  : linear-gradient(180deg, rgba(37, 64, 0, 0) 0%, rgba(37, 64, 0, 0.04) 100%); */
}

/* 左侧内容样式 */
.left-side {
  width     : calc(67% - 10px);
  box-sizing: border-box;

}

.left-side {
  width: calc((1000px - 10px) * 2 / 3);
  /* 左侧占总宽度减去间距的2/3 */
}

.right-side {
  width: calc((1000px - 10px) / 3);
  /* 右侧占总宽度减去间距的1/3 */
}

.left-three-articles-container {
  display        : flex;
  justify-content: space-between;
  gap            : 20px;
  width          : 100%;
  overflow       : hidden;
  /* 确保超出部分不会显示 */
  margin-top     : 20px;
  border-radius  : 4px;
}

.left-three-articles-container img {
  height    : 110px;
  width     : 318px;
  object-fit: cover;

}

.first-right-six-module {
  margin-top: 0;
}

.right-three-articles-container {
  height       : 110px;
  width        : 100%;
  margin-top   : 20px;
  position     : relative;
  overflow     : hidden;
  border-radius: 4px;

}

.right-three-articles-container img {
  position  : absolute;
  top       : 0;
  left      : 0;
  width     : 100%;
  height    : 100%;
  object-fit: cover;
}

/*---------------------*/

.council {
  position      : static;
  left          : 220px;
  top           : 32px;
  width         : 1000px;
  opacity       : 1;
  display       : flex;
  flex-direction: column;
  margin-right  : auto;
  margin-left   : auto;
  align-items   : center;
  padding       : 0px;
  gap           : 32px;
  z-index       : 0;
}

.council-img {
  position: absolute;
  width   : 40.85px;
  height  : 50px;
}

.council-title {
  height        : 38px;
  opacity       : 1;
  font-family   : 思源黑体;
  font-size     : 24px;
  font-weight   : 500;
  line-height   : 38px;
  letter-spacing: 0px;
  padding-left  : 25px;
  color         : #3D3D3D;
}

.expert {
  position      : static;
  left          : 0px;
  top           : 639px;
  margin-top    : 22px;
  border-radius : 0 !important;
  width         : 330px;
  height        : 305px;
  opacity       : 1;
  display       : flex;
  flex-direction: column;
  align-self    : stretch;
  /* box-shadow :  0 2px 3px rgba(0, 0, 0, 0.1);   大的下方阴影   */
  box-shadow    : 0 -1px 2px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index       : 3;
}

.expert-top {
  position       : static;
  left           : 0px;
  top            : 0px;
  width          : 330px;
  height         : 46px;
  opacity        : 1;
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  margin-top     : -17px;
  margin-left    : -24px;
  align-self     : stretch;
  background     : #FAFAFA;
  z-index        : 0;
}

.expert-top-text {
  position       : static;
  left           : 0px;
  top            : 0px;
  width          : 108px;
  height         : 46px;
  opacity        : 1;
  font-size      : 14px;
  font-weight    : 600;
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
  gap            : 12px;
  color          : #5B8C00;
  border-width   : 2px 0px 0px 0px;
  border-style   : solid;
  border-color   : #5B8C00;
  z-index        : 0;
}

.expert-footer {
  position      : static;
  margin-top    : 16px;
  width         : 56px;
  height        : 22px;
  opacity       : 1;
  font-family   : Roboto;
  font-size     : 14px;
  font-weight   : normal;
  line-height   : 22px;
  letter-spacing: 0px;
  color         : #5B8C00;
  z-index       : 0;
}