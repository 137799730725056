.carousel .slide img {
    width: 100%;
    height: 302px;
    border-radius: 4px;
  }
  
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 0.5;
  }
  
  .carousel .control-arrow:hover,
  .carousel.carousel-slider .control-arrow:hover {
    opacity: 1;
  }
  
  .carousel .control-dots .dot {
    background: #fff;
  }
  