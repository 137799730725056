.article-list-container {
    width: 725px;
    margin-top: 58px; 
    margin-bottom: 58px;
    padding: 16px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff; 
    min-height: 200px;
    border-top: 2px solid #5B8C00;
    box-sizing: border-box;
}

.article-item {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    /* 分割线 */
    border-bottom: 1px solid #f0f0f0; 
    padding: 16px 0;
    /* 顶部对齐 */
    align-items: flex-start;
}
.article-item:last-child {
    border-bottom: none; /* 去掉最后一个元素的分割线 */
}
.article-info {
    flex-grow: 1;
    padding-right: 16px;
    width: calc(100% - 206px); /* 确保标题和 desc不超过图片 */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 126px;
}

.article-title {
    font-size: 16px;
    color: #3D3D3D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    margin: 0;
}

.article-desc {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 8px;
    margin-bottom: 8px;
}

.article-time {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0; /* 去除底部间距 */
}

.article-img {
    width: 190px;
    height: 126px ;
    object-fit: cover;
}
.article-imgs {
    max-width: 150px;
    height: auto;
    display: block;
    object-fit: cover;
}

.pagination {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: transparent; 
    color: rgba(0, 0, 0, 0.88); 
    border: 1px solid transparent; 
    border-radius: 4px;
}

.pagination button.active {
    font-weight: bold;
    color: #5B8C00; 
    border: 1px solid #5B8C00;
    background-color: transparent;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.breadcrumb{
    position: absolute;
    margin-left: 312px;
    /* z-index:1; */
}
