
.footer-container {
    display: flex;
    width: 100%;
    height: 350px;
    background-color: #3F6600;
    color: white;
    box-sizing: border-box;
    justify-content: center; 
    align-items: center; 
}

/* 内容容器 */
.footer-content {
    display: flex;
    width: 1000px; 
    height: 207px;
    box-sizing: border-box; 
}

/* 每列的样式 */
.footer-column {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: flex-start;
    flex: 1;
    min-width: 0; 
}

/* 每列的右边距 */
.footer-column:not(:last-child) {
    margin-right: 160px; 
}


/* 标题样式 */
.footer-column .title {
    font-size: 15px; 
    font-weight: bold;
    margin-bottom: 10px; 
}

/* 列表项样式 */
.footer-column ul,
.footer-column dl {
    list-style: none; 
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 30px; 
}

/* 列表项样式 */
.footer-column li,
.footer-column dd {
    margin: 0;
}

/* 图片样式 */
.footer-column img {
    width: 126px;
    height: 126px;
}

/* 链接样式 */
.footer-column a {
    color: white; 
    text-decoration: underline; 
}
