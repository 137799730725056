.expert{
    position: static;
    left: 0px;
    top: 639px;
    width: 320px;
    height: 232px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding: 0px;
    align-self: stretch;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    z-index: 3;
}

.expert-top{
    position: static;
    left: 0px;
    top: 0px;
    width: 320px;
    height: 46px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    align-self: stretch;
    background: #FAFAFA;
    z-index: 0;
}
.top-text{
    position: static;
    left: 0px;
    top: 0px;
    width: 108px;
    height: 46px;
    opacity: 1;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    color:#5B8C00;
    box-sizing: border-box;
    border-width: 2px 0px 0px 0px;
    border-style: solid;
    border-color: #5B8C00;
    z-index: 0;
}

.head-img{
   /* 自动布局子元素 */
    position: static;
    left: 0px;
    top: 0px;
    width: 98px;
    height: 91px;
    border-radius: 4px;
    opacity: 1;
    z-index: 0;
}
.expert-list{
    position: static;
    top: 115px;
    width: 288px;
    height: 91px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 16px;
    align-self: stretch;
    margin-top: 10px;
    z-index: 1;
}
.expert-text{
    position: static;
    left: 114px;
    top: 0px;
    width: 174px;
    height: 91px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 4px;
    flex-grow: 1;
    z-index: 1;
    color: #404040;
}
.expert-name{
    position: static;
    margin-left: -1px;
    top: 0px;
    width: 100.12px;
    height: 21px;
    opacity: 1;
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: 0px;
    color: #000000;
    z-index: 0;
}

.expert-position{
    position: static;
    left: 0px;
    /* top: 29px; */
    width: 154px;
    height: 23px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding: 0px;
    /* gap: 11px; */
    align-self: stretch;
    z-index: 1;
}

.expert-content{
    position: static;
    left: 0px;
    width: 154px;
    height: 53px;
    opacity: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    gap: 3px;
    align-self: stretch;
    overflow: hidden; 
    -webkit-line-clamp: 2; 
    text-overflow: ellipsis;
    z-index: 1;
}