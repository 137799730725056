.article-container {
    width: 1000px; 
    margin: 0 auto; 
    display: flex; 
    align-items: flex-start; 
    box-sizing: border-box;
    gap: 20px; 
  }
.article{
        margin-top: 58px;

}
.article-channel {
    box-sizing: border-box;
    width: 255px;
    height: 60px;
    background-color: #7CB305;
    color: white;
    padding: 18px;
    /* margin-top: 58px; */
    align-items: left;
}
.article-search {
    box-sizing: border-box;
    width: 255px;
    height: 60px;
    background-color: #7CB305;
    color: white;
    padding: 18px;
    margin-top: 58px;
    align-items: left;
}
.article-list {
    flex-grow: 1; /* 占据剩余空间 */
}
.article-li{
background-color: #5B8C00;
}
.breadcrumb-article{
    position: absolute;
    margin-left: -34px;
    margin-top: -70px;

    /* z-index:1; */
}
